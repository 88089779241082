<template>
  <v-select
    ref="input"

    class="form-control"

    label="description"
    v-model="location"
    :options="options"
    :filterable="false"
    :disabled="disabled || readonly"

    :min-input-length="1"

    @input="onInput"
    @search="fetchLocationsDebounced"
  >
    <template
      slot="option"
      slot-scope="option"
    >
      <span>{{ option.description }}</span>
    </template>
  </v-select>
</template>

<style lang="scss">
@import '@/assets/sass/plugins/vue-select/index.scss';
</style>

<script>
import { v4 as uuid } from 'uuid';
import vSelect from '@bartversluijs/vue-select';

export default {
  name: 'locationAutocompleteInput',
  components: {
    vSelect,
  },

  props: {
    value: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value (value) {
      this.$set(this, 'location', value);
    },
  },
  data () {
    return {
      options: [],
      session: null,
      timeout: null,

      location: null,
    };
  },

  methods: {
    generateSessionToken () {
      this.$set(this, 'session', uuid());
    },

    focus () {
      this.$refs.input.focus();
    },

    onInput (value = null) {
      this.$set(this, 'location', value);
      this.$emit('input', this.location);
    },

    async fetchLocations (query) {
      clearTimeout(this.timeout);

      // Don't search when query is empty
      if (!query) {
        return;
      }

      this.$refs.input.toggleLoading(true);
      try {
        this.$set(this, 'options', await this.$ws.get('/v2/services/maps/places', {
          query: {
            q: query,
            session: this.session,
          },
        }));
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this });
        throw e;
      } finally {
        this.$refs.input.toggleLoading(false);
      }

      this.$refs.input.toggleLoading(false);
    },
    fetchLocationsDebounced (query) {
      clearTimeout(this.timeout);

      if (!query) {
        return;
      }

      this.$refs.input.toggleLoading(true);
      this.timeout = setTimeout(async () => {
        await this.fetchLocations(query);
        this.$refs.input.toggleLoading(false);
      }, 600);
    },
  },
};
</script>
